<template>
  <book-list-common></book-list-common>
</template>

<script>
export default {
  name: "zhBookList",
  components: {
    BookListCommon: (resolve) => {
      require(["@/components/BookListCommonZh.vue"], resolve);
    },
  },
  methods: {
  },
  beforeRouteLeave(to, from, next) {
    //从这个页面出去的时候执行这里
    this.ScrollTop = document.getElementsByClassName("mescroll")[0].scrollTop; // 得到当前高
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.ScrollTop) {
       setTimeout(function (){
         document.getElementsByClassName("mescroll")[0].scrollTop = vm.ScrollTop;
       },50)
      }
    });
  },
};
</script>

